import { IUser } from "@/definitions/core-typings/IUser";
import BaseService from "@/services/BaseService";
import {
  IAuthServiceLoginBody,
  IAuthServiceLoginResult,
} from "@/definitions/service-typings/AuthService";

class AuthService extends BaseService<IUser> {
  constructor() {
    super("");
  }

  async login(loginBody: IAuthServiceLoginBody) {
    return this.http.post<IAuthServiceLoginResult>("auth/login", loginBody);
  }

  async check() {
    return this.http.get<IAuthServiceLoginResult>("auth/check");
  }
}

export default new AuthService();
