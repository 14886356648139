import { IScoreModel } from "@/definitions/core-typings/IScoreModel";
import { IDefaultServiceResult } from "@/definitions/service-typings/IDefaultServiceResult";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class ScoreModelService extends BaseService<IScoreModel> {
  createAll(scoreModel: IScoreModel, config?: AxiosRequestConfig) {
    const formData = new FormData();
    formData.append("name", scoreModel.name);
    formData.append("file", scoreModel.file as Blob);
    formData.append("categories", JSON.stringify(scoreModel.categories));
    formData.append("insights", JSON.stringify(scoreModel.insights));
    formData.append("tags", JSON.stringify(scoreModel.tags));
    formData.append("entities", JSON.stringify(scoreModel.entities));

    return this.http.post<IDefaultServiceResult>(this.route, formData, config);
  }
}

export default new ScoreModelService("score/model");
