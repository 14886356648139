/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  INavigationState,
  NavigationMutations,
  NavigationActions,
  NavigationGetters,
} from "@/definitions/store-typings/Navigation";
import { SIDE_NAVIGATION_ITEMS } from "@/definitions/config/Navigation";
import { INavigationItem } from "@/definitions/ui-typings/INavigationItem";

const state: INavigationState = {
  sideNavigationOpen: false,
  sideNavigationItems: SIDE_NAVIGATION_ITEMS,
};

const mutations: MutationTree<INavigationState> = {
  [NavigationMutations.SET_SIDE_NAVIGATION_OPEN](
    state,
    sideNavigationOpen: boolean
  ) {
    state.sideNavigationOpen = sideNavigationOpen;
  },
};

const actions: ActionTree<INavigationState, any> = {
  [NavigationActions.TOOGLE_SIDE_NAVIGATION]({ commit }) {
    commit(
      NavigationMutations.SET_SIDE_NAVIGATION_OPEN,
      !state.sideNavigationOpen
    );
  },
};

const getters: GetterTree<INavigationState, any> = {
  [NavigationGetters.SIDE_NAVIGATION_OPEN](state): boolean {
    return state.sideNavigationOpen;
  },
  [NavigationGetters.SIDE_NAVIGATION_ITEMS](state): INavigationItem[] {
    return state.sideNavigationItems;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
