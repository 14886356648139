import { IDefaultServiceResult } from "@/definitions/service-typings/IDefaultServiceResult";
import { AxiosError } from "axios";
import { useToast } from "vue-toastification";

export const toast = useToast();

export const toastServiceError = (err: AxiosError<IDefaultServiceResult>) =>
  toast.error(
    err.response?.data.message || err.response?.statusText || err.message
  );
