import Vuex from "vuex";

import AuthStore from "@/store/modules/auth.store";
import NavigationStore from "@/store/modules/navigation.store";
import UiStore from "@/store/modules/ui.store";

export default new Vuex.Store({
  modules: {
    auth: AuthStore,
    navigation: NavigationStore,
    ui: UiStore,
  },
});
