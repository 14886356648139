import { App } from "vue";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $window: Window;
  }
}

const globalProperties = {
  install(app: App<Element>) {
    app.config.globalProperties.$window = window;
  },
};

export default globalProperties;
