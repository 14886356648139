import { IScore } from "@/definitions/core-typings/IScore";
import { IDefaultServiceResult } from "@/definitions/service-typings/IDefaultServiceResult";
import { IScoreBackfillFormState } from "@/definitions/ui-typings/IScoreBackfillFormState";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class ScoreService extends BaseService<IScore> {
  createBackfill(data: IScoreBackfillFormState, config?: AxiosRequestConfig) {
    if (data.range && data.range?.length > 1) {
      return this.http.post<IDefaultServiceResult>(
        `${this.route}/${data.score_id}/backfill`,
        {
          score_id: data.score_id,
          start: data.range[0],
          end: data.range[1],
        },
        config
      );
    }
  }
  deleteBackfill(scoreId: number, id: number, config?: AxiosRequestConfig) {
    return this.http.delete<IDefaultServiceResult>(
      `${this.route}/${scoreId}/backfill/${id}`,
      config
    );
  }
}

export default new ScoreService("score");
