/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  IUiState,
  UiMutations,
  UiActions,
  UiGetters,
} from "@/definitions/store-typings/Ui";

const state: IUiState = {
  isLoading: false,
};

const mutations: MutationTree<IUiState> = {
  [UiMutations.SET_IS_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};

const actions: ActionTree<IUiState, any> = {
  [UiActions.SET_IS_LOADING]({ commit }, isLoading: boolean) {
    commit(UiMutations.SET_IS_LOADING, isLoading);
  },
};

const getters: GetterTree<IUiState, any> = {
  [UiGetters.IS_LOADING](state): boolean {
    return state.isLoading;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
