import { ILevel } from "@/definitions/core-typings/ILevel";
import BaseService from "@/services/BaseService";
import { AxiosRequestConfig } from "axios";

class LevelService extends BaseService<ILevel> {
  constructor() {
    super("level");
  }

  getAllByCompanyId(companyId: number, config?: AxiosRequestConfig) {
    return this.http.get<ILevel[]>(`${this.route}/${companyId}`, config);
  }
}

export default new LevelService();
