import { IDefaultServiceResult } from "@/definitions/service-typings/IDefaultServiceResult";
import http from "@/utils/httpCommon";
import { AxiosInstance, AxiosRequestConfig } from "axios";

export default class BaseService<T> {
  route: string;
  http: AxiosInstance;

  constructor(route: string) {
    this.route = route;
    this.http = http;
  }

  getAll<ResponseT = T[]>(config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(this.route, config);
    // .then((response: AxiosResponse) => response.data);
  }

  get<ResponseT = T>(id: number | string, config?: AxiosRequestConfig) {
    return this.http.get<ResponseT>(`${this.route}/${id}`, config);
    // .then((response: AxiosResponse) => response.data);
  }

  create<ResponseT = IDefaultServiceResult, DataT = T>(
    data: DataT,
    config?: AxiosRequestConfig
  ) {
    return this.http.post<ResponseT>(this.route, data, config);
    // .then((response: AxiosResponse) => response.data);
  }

  update<ResponseT = IDefaultServiceResult, DataT = T>(
    id: number | string,
    data: DataT,
    config?: AxiosRequestConfig
  ) {
    return this.http.put<ResponseT>(`${this.route}/${id}`, data, config);
    // .then((response: AxiosResponse) => response.data);
  }

  delete<ResponseT = IDefaultServiceResult>(
    id: number | string,
    config?: AxiosRequestConfig
  ) {
    return this.http.delete<ResponseT>(`${this.route}/${id}`, config);
    // .then((response: AxiosResponse) => response.data);
  }
}
