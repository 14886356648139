import * as validators from "@vuelidate/validators";

import { ValidationRuleWithoutParams } from "@vuelidate/core";

export const required = validators.required;
export const minLength = validators.minLength;
export const maxLength = validators.maxLength;
export const email = validators.email;
export const sameAs = validators.sameAs;

export const containsUppercase: ValidationRuleWithoutParams = {
  $validator(value: string | undefined) {
    return /[A-Z]/.test(value || "");
  },
};
export const containsLowercase: ValidationRuleWithoutParams = {
  $validator(value: string | undefined) {
    return /[a-z]/.test(value || "");
  },
};
export const containsNumber: ValidationRuleWithoutParams = {
  $validator(value: string | undefined) {
    return /[0-9]/.test(value || "");
  },
};
export const containsSpecial: ValidationRuleWithoutParams = {
  $validator(value: string | undefined) {
    return /[#?!@$%^&*-]/.test(value || "");
  },
};
