export interface IUiState {
  isLoading: boolean;
}

export enum UiMutations {
  SET_IS_LOADING = "ui_setIsLoading",
}

export enum UiActions {
  SET_IS_LOADING = "ui_setIsLoading",
}

export enum UiGetters {
  IS_LOADING = "ui_isLoading",
}
