import { INavigationItem } from "@/definitions/ui-typings/INavigationItem";

export interface INavigationState {
  sideNavigationOpen: boolean;
  sideNavigationItems: INavigationItem[];
}

export enum NavigationMutations {
  SET_SIDE_NAVIGATION_OPEN = "setSideNavigationOpen",
}

export enum NavigationActions {
  TOOGLE_SIDE_NAVIGATION = "toogleSideNavigation",
}

export enum NavigationGetters {
  SIDE_NAVIGATION_OPEN = "sideNavigationOpen",
  SIDE_NAVIGATION_ITEMS = "sideNavigationItems",
}
