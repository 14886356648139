/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from "vuex";
import router from "@/router";
import { IUser } from "@/definitions/core-typings/IUser";
import { IAuthServiceLoginResult } from "@/definitions/service-typings/AuthService";
import {
  IAuthState,
  AuthMutations,
  AuthActions,
  AuthGetters,
} from "@/definitions/store-typings/Auth";

const state: IAuthState = {
  user: undefined,
};

const mutations: MutationTree<IAuthState> = {
  [AuthMutations.SET_USER](state, user: IUser) {
    state.user = user;
  },
};

const actions: ActionTree<IAuthState, any> = {
  [AuthActions.LOGIN]({ commit }, loginResult: IAuthServiceLoginResult) {
    commit(AuthMutations.SET_USER, loginResult.user);
    if (loginResult.jwt) {
      localStorage.setItem("token", loginResult.jwt);
    }
  },
  [AuthActions.LOGOUT]({ commit }) {
    commit(AuthMutations.SET_USER, null);
    localStorage.removeItem("token");
    router.push("/login").then();
  },
};

const getters: GetterTree<IAuthState, any> = {
  [AuthGetters.USER]: (state) => state.user,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
