import { INavigationItem } from "@/definitions/ui-typings/INavigationItem";
import { HomeIcon, LightningBoltIcon } from "@heroicons/vue/outline";

export const SIDE_NAVIGATION_ITEMS: INavigationItem[] = [
  { text: "Home", icon: HomeIcon, to: "/" },
  {
    text: "Score",
    icon: LightningBoltIcon,
    children: [
      { text: "Modelo", to: "/score/model" },
      { text: "Score", to: "/score" },
    ],
  },
];
