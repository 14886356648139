import store from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { beforeEach, idIsPositiveInteger } from "@/router/routeGuards";
import AppTemplate from "@/views/templates/AppTemplate.vue";
import LoginPage from "@/views/LoginPage.vue";
import HomePage from "@/views/HomePage.vue";
import ProfilePage from "@/views/ProfilePage.vue";
import ScoreModelPage from "@/views/ScoreModelPage.vue";
import ScoreModelDetailPage from "@/views/ScoreModelDetailPage.vue";
import ScoreModelFormPage from "@/views/ScoreModelFormPage.vue";
import ScorePage from "@/views/ScorePage.vue";
import ScoreDetailPage from "@/views/ScoreDetailPage.vue";
import ScoreFormPage from "@/views/ScoreFormPage.vue";
import ScoreBackfillFormPage from "@/views/ScoreBackfillFormPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import { AuthActions } from "@/definitions/store-typings/Auth";
import { FormAction } from "@/definitions/ui-typings/FormAction";

const routes: RouteRecordRaw[] = [
  {
    name: "Login",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "Logout",
    path: "/logout",
    component: LoginPage,
    beforeEnter: async () => {
      await store.dispatch(AuthActions.LOGOUT);
      return true;
    },
  },
  {
    name: "AppTemplate",
    path: "/",
    component: AppTemplate,
    children: [
      {
        name: "Home",
        path: "/",
        component: HomePage,
      },
      {
        name: "Profile",
        path: "/profile",
        component: ProfilePage,
      },
      {
        name: "ScoreModel",
        path: "/score/model",
        children: [
          {
            name: "ListScoreModel",
            path: "",
            component: ScoreModelPage,
          },
          {
            name: "ViewScoreModelById",
            path: ":id",
            component: ScoreModelDetailPage,
            beforeEnter: [idIsPositiveInteger],
          },
          {
            name: "EditScoreModelById",
            path: ":id/edit",
            component: ScoreModelFormPage,
            beforeEnter: [idIsPositiveInteger],
            props: { action: FormAction.EDIT },
          },
          {
            name: "CreateScoreModel",
            path: "create",
            component: ScoreModelFormPage,
            props: { action: FormAction.CREATE },
          },
        ],
      },
      {
        name: "Score",
        path: "/score",
        children: [
          {
            name: "ListScore",
            path: "",
            component: ScorePage,
          },
          {
            name: "ViewScoreById",
            path: ":id",
            component: ScoreDetailPage,
            beforeEnter: [idIsPositiveInteger],
          },
          {
            name: "EditScoreById",
            path: ":id/edit",
            component: ScoreFormPage,
            beforeEnter: [idIsPositiveInteger],
            props: { action: FormAction.EDIT },
          },
          {
            name: "CreateScore",
            path: "create",
            component: ScoreFormPage,
            props: { action: FormAction.CREATE },
          },
          {
            name: "CreateScoreBackfill",
            path: ":id/backfill",
            component: ScoreBackfillFormPage,
          },
        ],
      },
      {
        path: "/:catchAll(.*)",
        component: NotFoundPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(beforeEach);

export default router;
