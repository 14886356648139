import { IUser } from "@/definitions/core-typings/IUser";

export interface IAuthState {
  user?: IUser;
}

export enum AuthMutations {
  SET_USER = "auth_setUser",
}

export enum AuthActions {
  LOGIN = "auth_login",
  LOGOUT = "auth_logout",
}

export enum AuthGetters {
  USER = "auth_user",
}
