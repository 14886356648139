import store from "@/store";
import AuthService from "@/services/AuthService";
import {
  NavigationGuardNext,
  NavigationGuardWithThis,
  RouteLocationNormalized,
} from "vue-router";
import { isPositiveInteger } from "@/utils/number";
import { AuthActions, AuthGetters } from "@/definitions/store-typings/Auth";

export const beforeEach: NavigationGuardWithThis<undefined> = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.path != "/login" && store.getters[AuthGetters.USER] === undefined) {
    AuthService.check().then(
      (response) => {
        store.dispatch(AuthActions.LOGIN, response.data);
        next();
      },
      () => next({ name: "Login" })
    );
  } else {
    next();
  }
};

export const idIsPositiveInteger: NavigationGuardWithThis<undefined> = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const id: string | string[] = to.params.id;
  if (typeof id === "string" && isPositiveInteger(id)) next();
  else next("not-found");
};
